<template>
  <div class="container">
    <div class="layout">
      <div class="main">
        <!-- 警情类型 -->
        <div class="row row1">
          <div class="label">警情类型</div>
          <div class="content">
            <div style="height: 100%; width: 94%">
              <el-select class="select-task-type" v-model="createTaskForm.taskTarget" placeholder="请选择任务目的" :popper-append-to-body="false">
                <el-option v-for="item in taskPurposeList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="row row2">
          <div class="label">警情点</div>
          <div class="content">
            <div style="
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 5px 0;
              " v-for="(item, index) in jqdList" :key="index">
              <div style="
                  height: 100%;
                  width: 94%;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                ">
                <div class="input">
                  <el-input v-model="item.label" placeholder="请输入警情点地址"></el-input>
                </div>
                <div class="input">
                  <el-input v-model="item.value" placeholder="选择坐标点">
                    <i slot="suffix">
                      <img src="@/assets/img/upload.png" @click="handleShowMapDialog(index)" />
                    </i>
                  </el-input>
                </div>
              </div>
              <div style="height: 100%; width: 4%">
                <img src="@/assets/img/delete.png" v-if="index > 0" @click="handleRemoveJQD(index)" />
              </div>
            </div>
          </div>
        </div>
        <div class="row row3">
          <div class="label"></div>
          <div class="content">
            <div style="height: 100%; width: 94%">
              <div class="jqd-add" @click="handleJQDAdd">
                <img src="@/assets/img/add.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="row row4">
          <div class="label">警情详情</div>
          <div class="content">
            <div style="height: 100%; width: 94%">
              <el-input type="textarea" :autosize="{ minRows: 10, maxRows: 14 }" placeholder="请输入内容" v-model="createTaskForm.textarea2">
              </el-input>
            </div>
          </div>
        </div>
        <div class="row row6">
          <div class="label"></div>
          <div class="content">
            <div style="height: 100%; width: 94%">
              <el-button class="reset" type="info" @click="resetForm">重置</el-button>
              <el-button class="submit" type="primary" style="margin-left: 80px" @click="send">发起警情</el-button>
            </div>
          </div>
        </div>
      </div>
      <!-- 地图弹窗 -->
      <el-dialog title="" :visible.sync="mapDialogVisible" :modal="false" fullscreen custom-class="alert-point-map-dialog" show-close close-on-press-escape>
        <div class="alert-point-map" id="map">
          <img class="close" src="@/assets/img/i151.png" @click="handleCloseMapDialog" />
          <!-- 提示 -->
          <div class="tips">双击确认坐标</div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// 引用bus
import Bus from "@/assets/ligature.js";
import API from "@/api";
import dayjs from "dayjs";
import _ from "lodash";
// 芜湖边界
import WuHuGeoJsonBorder from "@/data/geo/geo_wuhu_border.json";
import { Utils } from "@/lib/cesium";
let alertPointMapViewer = null;
export default {
  props: {
    selectedFlag: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      createTaskForm: {
        taskTarget: "fireFighting",
        jqd1: "",
        jqd2: "",
        jqd3: "",
        textarea2: "",
      },
      taskPurposeList: [
        { value: "polling", label: "巡检" },
        { value: "mapping", label: "测绘" },
        { value: "fireFighting", label: "灭火" },
        { value: "search", label: "探查" },
      ],
      // jqdList: [
      //   { value: '118.424554, 31.381827', label: '荷塘新村' },
      //   { value: '118.253585, 31.201501', label: '都市浪漫园' },
      //   { value: '118.409609, 31.204058', label: '芜湖市博物馆' },
      // ],
      jqdList: [{ label: "", value: "" }],
      mapDialogVisible: false,
      jqdIndex: 0,
      // loading: false,
    };
  },
  methods: {
    resetForm() {
      this.createTaskForm.taskTarget = "fireFighting";
      this.jqdList = [{ label: "", value: "" }];
      this.createTaskForm.textarea2 = "";
    },
    async send() {
      let data = {};
      data.needHandle = true;
      data.noticeType = "jingqing";
      let cont = {};
      cont.createTime = dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss");
      cont.alert_content = "荷塘新村发现火情";
      cont.alert_time = dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss");
      cont.alert_type = this.createTaskForm.taskTarget;
      // cont.alert_id = 'JQ20240423125453001';
      cont.alert_positions = [];
      // 校验警情点
      let jqdFlag = 0;
      const regex = /^-?\d+(\.\d+)?,-?\d+(\.\d+)?$/; //正则表达式判断是否符合经纬度坐标格式
      this.jqdList.forEach((v) => {
        // console.log('校验警情点数据', v);
        v.label = v.label.trim();
        v.value = v.value.trim();
        const [lng, lat] = v.value.split(",").map(Number); //拆分v.value的经纬度来判断范围
        if (!v.label) {
          jqdFlag = 1;
          console.log("label为空");
        } else if (!v.value) {
          jqdFlag = 2;
        } else if (!regex.test(v.value)) {
          jqdFlag = 3;
        } else if (lng < -180 || lng > 180 || lat < -90 || lat > 90) {
          jqdFlag = 4;
          console.log(lng, lat);
        }

        console.log(v.label);
        console.log(v.value);
        cont.alert_positions.push(v);
      });
      if (cont.alert_positions.length > 10) {
        this.$message({
          type: "success",
          message: "新建警情点不能超过10个",
        });
        return;
      }
      // 判断消息类型，给出不同的报错提示
      switch (jqdFlag) {
        case 1:
          this.$message({
            type: "warning",
            message: "警情点地址不能为空",
          });
          return;
        case 2:
          this.$message({
            type: "warning",
            message: "警情点坐标不能为空",
          });
          return;
        case 3:
          this.$message({
            type: "warning",
            message: "警情点坐标格式不正确",
          });
          return;
        case 4:
          this.$message({
            type: "warning",
            message: "警情点坐标超出范围",
          });
          return;
      }
      let title = "";
      cont.alert_positions.forEach((v) => {
        title += `${v.label}-`;
      });
      title = title.trim();
      title = title.substring(0, title.length - 1);
      if (title == "") {
        title = "警情任务";
      }
      data.noticeTitle = title;
      cont.alert_content = title;
      data.noticeContent = JSON.stringify(cont);
      console.log("创建警情任务参数", data);
      let res = await API.WHXF.getJQCreateNoticeAlert(data);
      console.log("创建警情任务返回值", res);
      if (res) {
        this.$message({
          type: "success",
          message: "新建警情成功",
        });
        this.resetForm();
        window.close();
      } else {
        this.$message({
          type: "success",
          message: "新建警情失败",
        });
      }
    },
    /**@Description :  ******************************************* 点击添加一个警情点
     **@Date: 2024-06-11 15:28:12
     */
    handleJQDAdd() {
      this.jqdList.push({ label: "", value: "" });
    },
    /**@Description :  ******************************************* 删除警情点
     **@Date: 2024-06-11 16:27:13
     */
    handleRemoveJQD(index) {
      this.jqdList.splice(index, 1);
    },
    /**@Description :  ******************************************* 弹出地图弹窗
     **@Date: 2024-06-11 16:36:22
     */
    handleShowMapDialog(index) {
      this.mapDialogVisible = true;
      // this.loading = true;
      this.jqdIndex = index;
      this.$nextTick(() => {
        this.initCesiumMap();
      });
    },
    /**@Description :  ******************************************* 初始化cesium地图
     **@Date: 2024-06-11 16:46:09
     */
    initCesiumMap() {
      if (!alertPointMapViewer) {
        Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(
          75.0,
          20.0,
          130.0,
          60.0
        );
        alertPointMapViewer = new Cesium.Viewer("map", {
          animation: false, //是否显示动画控件
          shouldAnimate: true,
          homeButton: false, //是否显示Home按钮
          fullscreenButton: false, //是否显示全屏按钮
          baseLayerPicker: false, //是否显示图层选择控件
          geocoder: false, //是否显示地名查找控件
          timeline: false, //是否显示时间线控件
          sceneModePicker: true, //是否显示投影方式控件
          navigationHelpButton: false, //是否显示帮助信息控件
          infoBox: false, //是否显示点击要素之后显示的信息
          requestRenderMode: true, //启用请求渲染模式
          maximumRenderTimeChange: Infinity,
          scene3DOnly: false, //每个几何实例将只能以3D渲染以节省GPU内存
          sceneMode: 3, //初始场景模式 1 2D模式 2 2D循环模式 3 3D模式  Cesium.SceneMode
          fullscreenElement: document.body, //全屏时渲染的HTML元素 暂时没发现用处
          selectionIndicator: false,
          imageryProvider: new Cesium.WebMapTileServiceImageryProvider({
            url: "https://{s}.tianditu.gov.cn/img_c/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE={style}&TILEMATRIXSET={TileMatrixSet}&FORMAT=tiles&TILEMATRIX={TileMatrix}&TILEROW={TileRow}&TILECOL={TileCol}&tk=094247775c509d9f6f2856f8fd660b33",
            layer: "img",
            style: "default",
            format: "tiles",
            tileMatrixSetID: "c",
            credit: new Cesium.Credit("天地图全球影像服务"),
            tilingScheme: new Cesium.GeographicTilingScheme(), //采用经纬度投影
            subdomains: ["t0", "t1", "t2", "t3", "t4", "t5", "t6", "t7"],
            tileMatrixLabels: [
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
            ],
            maximumLevel: 21,
          }),
          skyBox: false, //不显示天空盒
          orderIndependentTranslucency: true, //如果为true，并且配置支持它，则使用顺序无关的半透明性。
          contextOptions: { webgl: { alpha: true } },
          shadows: false, // 关闭阴影
          targetFrameRate: 60, //设置最大频率数
          useBrowserRecommendedResolution: false,
        });
        let tiandituImageLabelLayer1 = new Cesium.ImageryLayer(
          new Cesium.WebMapTileServiceImageryProvider({
            url: "https://{s}.tianditu.gov.cn/cia_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=cia&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default.jpg&tk=094247775c509d9f6f2856f8fd660b33",
            layer: "tdtAnnoLayer",
            style: "default",
            format: "image/jpeg",
            tileMatrixSetID: "GoogleMapsCompatible",
            subdomains: ["t0", "t1", "t2", "t3", "t4", "t5", "t6", "t7"],
            crossorigin: "anonymous",
          })
        );
        alertPointMapViewer.imageryLayers.add(tiandituImageLabelLayer1);
        this.init_wuhu_border();
        // this.loading = false;
        let handler = new Cesium.ScreenSpaceEventHandler(
          alertPointMapViewer.scene.canvas
        );
        handler.setInputAction((movement) => {
          console.log("movement", movement);

          let cartesian = alertPointMapViewer.scene.camera.pickEllipsoid(
            movement.position,
            alertPointMapViewer.scene.globe.ellipsoid
          );
          console.log("点击拾取的坐标：", cartesian);
          if (Cesium.defined(cartesian)) {
            let cartographic = Cesium.Cartographic.fromCartesian(cartesian);
            let longitudeString = Cesium.Math.toDegrees(
              cartographic.longitude
            ).toFixed(6);
            let latitudeString = Cesium.Math.toDegrees(
              cartographic.latitude
            ).toFixed(6);
            let heightString = cartographic.height.toFixed(2);
            console.log(
              "经度：" +
              longitudeString +
              "，纬度：" +
              latitudeString +
              "，高度：" +
              heightString
            );
            this.jqdList[this.jqdIndex].value =
              longitudeString + "," + latitudeString;
            // 获取坐标点之后，关闭地图弹框
            this.mapDialogVisible = false;
          }
        }, Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);

        let lon = 118.22073,
          lat = 29.50576,
          height = 151812.321476599747;
        alertPointMapViewer.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(lon, lat, height),
          orientation: {
            heading: 0.006918732929549698,
            pitch: -0.6928665494487145,
            roll: 7.638334409421077e-14,
          },
          duration: 3,
          complete: () => { },
        });
      }

      // this.loading = false;
    },
    /**@Description :  ******************************************* 关闭地图弹窗
     **@Date: 2024-06-11 16:55:20
     */
    handleCloseMapDialog() {
      this.mapDialogVisible = false;
    },
    init_wuhu_border() {
      let promise = Cesium.GeoJsonDataSource.load(WuHuGeoJsonBorder);
      promise.then((dataSource) => {
        let entities = dataSource.entities.values;
        for (let i = 0; i < entities.length; i++) {
          let entity = entities[i];
          entity._name = "border" + "_" + entity._name + "_wuhu";
          entity.polygon.outline = true;
          entity.polygon.fill = false;
          entity.polygon.outlineColor =
            Cesium.Color.fromCssColorString("#ffd33c");
          let positions = entity.polygon.hierarchy._value.positions;
          entity.polyline = {
            positions: positions.map((item) => {
              let c = Cesium.Cartesian3.clone(item);
              let wgs84 = Utils.transformCartesian2WGS84(c);
              return Utils.transformWGS842Cartesian(wgs84);
            }),
            width: 4,
            material: Cesium.Color.fromCssColorString("#36b558"),
          };
        }
        alertPointMapViewer.dataSources.add(dataSource);
        dataSource.show = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 100;
  background-color: #1f2030;
  box-sizing: border-box;
  padding: 18px;

  .layout {
    height: 100%;
    width: 100%;
    position: relative;
    background-color: #0a293a;

    .main {
      position: absolute;
      top: 10%;
      left: 5%;
      width: 40%;
      height: 100%;

      .row {
        // height: 30%;
        width: 85%;
        // border: 1px solid #ff0000;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 20px;

        .label {
          color: #ffffff;
          width: 80px;
          height: 22px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 16px;
          color: #ffffff;
          line-height: 19px;
          text-align: center;
          font-style: normal;
          text-transform: none;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }

        .content {
          height: 100%;
          width: calc(100% - 80px);
        }

        /deep/ .el-input__inner {
          // width: 150px;
          // height: 40px;
          background: #0a293a;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid rgba(255, 255, 255, 0.21);
          color: #fff;
        }
      }

      .row2 {
        position: relative;

        .label {
          // position: absolute;
          top: 10px;
        }

        .input {
          width: 48%;
        }

        img {
          cursor: pointer;
        }

        .content {
          max-height: 300px;
          overflow-y: auto;
          // -ms-overflow-style: none;
          // scrollbar-width: none;
          /* firefox */
        }

        .content::-webkit-scrollbar {
          // display: none;
        }

        /deep/ .el-input__suffix {
          display: flex;
          align-items: center;
        }
      }

      .row3 {
        .jqd-add {
          border: 1px solid red;
          // margin-top: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 36px;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid rgba(255, 255, 255, 0.2);
          cursor: pointer;
        }
      }

      .row4 {
        justify-content: center;

        /deep/ .el-textarea__inner {
          background: #0a293a;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid rgba(255, 255, 255, 0.21);
          color: #fff;
        }
      }

      .row6 {
        .content {
          div {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .reset {
              background-color: #133d54;
              border: 0;
            }

            .submit {
              background-color: #007aff;
              border: 0;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.alert-point-map-dialog {
  position: relative;
  width: 100%;
  height: 100%;

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1000;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  .tips {
    position: absolute;
    top: 5%;
    left: 45%;
    height: 30px;
    width: auto;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 10px;
    border-radius: 5px;
    letter-spacing: 2px;
  }

  .el-dialog__header {
    display: none !important;
  }

  .el-dialog__body {
    width: 100% !important;
    height: 100% !important;
    padding: 0;
    // overflow: hidden;
  }
}

.alert-point-map {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
  // border:1px solid #ff0000;
}
</style>
<style lang="scss" scoped>
@import "~@/styles/wuhuCommon.scss";
</style>
